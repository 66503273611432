<template>
  <div>
        <kendo-grid
            ref="grid" 
            :data-source="dataSource"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            :resizable="true"
            >
        </kendo-grid>
  </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import {globalfunc} from '../../../../shared/GlobalFunction';
import productionDeliveryOrderServices from '../Script/ProductionDeliveryOrderServices';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'ProductionCreateGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['createClick'],
    data: function () {
        return  {
            dataSource:  new kendo.data.DataSource ({
                    page: 1,
                    pageSize: kendo_grid.default_form_grid_pagesize,
                    serverPaging: true,
                    serverFiltering: true,
                    serverSorting: true,
                    transport: {
                        read: {
                            contentType: "application/json",
                            url: apiBaseUrl,
                            type: "POST",
                            data: function(e) {
                                const paging = {
                                    Page : {Limit: e.pageSize,Page: e.page},
                                    Filter : globalfunc.filterQueryBuilder(e.filter),
                                    OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                                }
                                const variables = {
                                    paging : paging
                                }
                                return { query: productionDeliveryOrderServices.readProductionInvoiceQuery(),
                                         variables: variables};
                            },
                            beforeSend: function (req) {
                                req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                                req.setRequestHeader('Path', window.location.pathname);
                            }
                        },
                        parameterMap: function(options, operation) {
                            return  kendo.stringify({
                                query: options.query,
                                variables: options.variables
                            });
                        },
                        cache: false,
                    },
                    schema: {
                        data: function (response) {
                            if(response.data.GetProductionInvoice.ProductionInvoiceReponse == null){
                                return [];
                            }else{
                                return response.data.GetProductionInvoice.ProductionInvoiceReponse;
                            }
                        },
                        total: function (response) {
                            if(response.data.GetProductionInvoice.total == null){
                                return 0;
                            }else{
                                return response.data.GetProductionInvoice.total;
                            }
                        },
                        model: {
                            fields: {
                                invoice_date: {type: "date"},
                                invoice_due_date: {type: "date"},
                                invoice_received_date: {type: "date"},
                            }
                        }
                    }
                }),
            columns:  [
                { title: "Action", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold; vertical-align:middle;" }, attributes: { "class": "k-text-center" },
                    template: '<div class="btn-group"> <button type="button" class="btn btn-success btn-md" id="CreateButton"> Create </button> </div>'
                },
                { field: "invoice_number", width: 200, title: "Nomor Bukti", headerAttributes:{ style: "text-align:center; font-weight:bold; vertical-align:middle;" } },
                { field: "invoice_date", width: 200, title: "Tanggal Invoice", headerAttributes:{ style: "text-align:center; font-weight:bold; vertical-align:middle;" },
                    template: "#= kendo.toString(kendo.parseDate(invoice_date, 'yyyy-MM-dd'), 'dd-MM-yyyy') #" },
                { field: "invoice_due_date", width: 200, title: "Tanggal Jatuh Tempo Invoice", headerAttributes:{ style: "text-align:center; font-weight:bold; vertical-align:middle;" },
                    template: "#= kendo.toString(kendo.parseDate(invoice_due_date, 'yyyy-MM-dd'), 'dd-MM-yyyy') #" },
                { field: "invoice_received_date", width: 200, title: "Tanggal Penerimaan Invoice", headerAttributes:{ style: "text-align:center; font-weight:bold; vertical-align:middle;" },
                    template: "#= kendo.toString(kendo.parseDate(invoice_received_date, 'yyyy-MM-dd'), 'dd-MM-yyyy') #" },
                { field: "production_code", width: 200, title: "Kode Produksi", headerAttributes:{ style: "text-align:center; font-weight:bold; vertical-align:middle;" } },
                { field: "producer_name", width: 200, title: "Nama Producer", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        };
    },
    mounted: async function() {
        var grid = this.$refs.grid.kendoWidget();
        var createClick = this.$props.createClick;

        var GridElement = grid.element;

        GridElement.on("click", "#CreateButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            
            createClick(dataItem.invoice_id)
        })
    },
    methods: {
       
    }
}
</script>

<style scoped>
</style>
