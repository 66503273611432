import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class ProductionDeliveryOrderService {
    readProductionDeliveryOrderQuery(){
        var query = `query($paging:ServerPaging, $status:String){
            GetProductionDelivery(Paging:$paging, Status:$status){
                ProductionDeliveryResponse {
                    created_at
                    last_update
                    delivery_order_id
                    delivery_order_number
                    delivery_order_type
                    status
                    delivery_order_date
                    delivery_order_received_date
                    producer_id
                    producer_name
                    storage_id
                    storage_name
                    invoice_id
                    invoice_number
                    notes
                    printed_notes
                    address
                    attachment
                    created_by
                    detail {
                      created_at
                      last_update
                      delivery_order_id
                      item_id
                      item_name
                      product_code
                      uom
                      quantity
                      invoice_id
                      delivered_quantity
                      remaining_quantity
                      invoice_quantity
                    }
                  }
                total
            }
        }`;
        return query;
    }

    async getDetailProductionDeliveryOrder(id){
        const variables = {
            id : id
        }
        var query = gql`query($id:Int){
            GetProductionDelivery(DeliveryOrderId:$id){ 
                ProductionDeliveryResponse {
                    created_at
                    last_update
                    delivery_order_id
                    delivery_order_number
                    delivery_order_type
                    status
                    delivery_order_date
                    delivery_order_received_date
                    producer_id
                    producer_name
                    storage_id
                    storage_name
                    invoice_id
                    invoice_number
                    notes
                    printed_notes
                    address
                    attachment
                    created_by
                    detail {
                        created_at
                        last_update
                        delivery_order_id
                        item_id
                        item_name
                        product_code
                        uom
                        quantity
                        invoice_id
                        delivered_quantity
                        remaining_quantity
                        invoice_quantity
                    }
                }
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);

        return result.data.GetProductionDelivery.ProductionDeliveryResponse[0];
    }

    readProductionInvoiceQuery(){
        var query = `query($paging:ServerPaging){
            GetProductionInvoice(Paging:$paging, Status:"Payment"){
                ProductionInvoiceReponse {
                    invoice_id
                    invoice_number
                    status
                    invoice_date
                    invoice_due_date
                    invoice_received_date
                    production_id
                    producer_id
                    producer_name
                    production_code
                }
                total
            }
        }`;
        return query;
    }

    async readProductionInvoiceDetailQuery(id){
        const variables = {
            id : id
        }
        var query = gql`query($id:Int!){
            GetProductionInvoice(InvoiceId:$id){
                ProductionInvoiceReponse {
                    created_at
                    last_update
                    invoice_id
                    invoice_number
                    status
                    invoice_date
                    invoice_due_date
                    invoice_received_date
                    ppn_tax_percentage
                    currency_id
                    currency_name
                    exchange_rate
                    notes
                    created_by
                    production_id
                    producer_id
                    producer_name
                    producer_address
                    storage_id
                    storage_name
                    production_code
                }
            }
        }`;
        var result = await globalfunc.defaultApolloMutationDefault(query, variables);
        return result.data.GetProductionInvoice.ProductionInvoiceReponse[0];
    }

    async getProductionDeliveryOrderable(id, type){
        const variables = {
            id : [id],
            type : type
        }
        var query = gql`query($id:[Int!]!, $type:Int!){
            GetProductionDeliveryOrderableItemList(invoiceIds:$id, DeliveryOrderType:$type) {
                invoice_id
                item_id
                item_name
                product_code
                barcode
                type_detail_id
                uom
                invoice_quantity
                delivered_quantity
                remaining_quantity
                quantity
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);

        return result.data.GetProductionDeliveryOrderableItemList;
    }

    // async getProducerQuery(id){
    //     const variables = {
    //         id : id,
    //     }
    //     var query = gql`query($id:Int){
    //         GetContact(ContactId:$id){
    //             contact_id
    //             contact_code
    //             contact_name
    //             metadata
    //         }
    //     }`;
    //     var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        
    //     return result.data.GetContact[0];
    // }  

    async addQuery(variables){
        var query = gql`mutation($data:NewProductionDeliveryOrder!){
            CreateProductionDeliveryOrder(NewProductionDeliveryOrder:$data)
        }`;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async editQuery(variables){
        var query = gql`mutation($id:Int!,$data:NewProductionDeliveryOrder!){
            UpdateProductionDeliveryOrder(ProductionDeliveryOrderId:$id,NewProductionDeliveryOrder:$data)
        }`;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
}

export default new ProductionDeliveryOrderService();
