<template>
    <div>
        <div class="modal fade" id="ProductionDeliveryOrderStatusModal" tabindex="-1" aria-labelledby="ProductionDeliveryOrderStatusModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="ProductionDeliveryOrderStatusModalLabel" class="font-weight-bold">{{ModalTitle}}{{ProductionDeliveryOrderNo}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <div class="p-3">
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Status</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                                    <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                        </div>
                        <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import {globalfunc} from '../../../../shared/GlobalFunction';
import productionDeliveryOrderServices from '../Script/ProductionDeliveryOrderServices';
import { response } from '../../../../infrastructure/constant/response';
import { sweet_alert } from '../../../../infrastructure/constant/variable';

export default {
    name: 'ProductionDeliveryOrderStatusForm',
    components: {
    },
    props: ['reload'],
    data: function () {
        return {
            ModalTitle : '',
            Id : '',
            ProductionDeliveryOrderNo : '',
            Status : '',
            StatusData : [],
        }
    },
    methods: {
        async statusClick(data){
            this.ModalTitle = 'Edit Status Invoice: ';
            this.Id = data.delivery_order_id;
            this.ProductionDeliveryOrderNo = data.delivery_order_number;
            
            this.StatusData = await globalfunc.globalDropdown('ddl_status_production_delivery_order');
            this.Status = data.status;
            
            window.$('#ProductionDeliveryOrderStatusModal').modal('show');
        },
        async saveClick(){
            var data = await productionDeliveryOrderServices.getDetailProductionDeliveryOrder(this.Id);

            //set grid
            var itemGridRequestOrder = [];

            if(data.detail.length != 0 ){
                for (let i = 0; i < data.detail.length; i++) {
                    var str = { item_id: data.detail[i].item_id,
                                uom: data.detail[i].uom,
                                quantity: data.detail[i].quantity
                            } 
                    itemGridRequestOrder.push(str);
                }
            };

            var deliveryData = {
                delivery_order_number: data.delivery_order_number,
                delivery_order_type: data.delivery_order_type,
                status: this.Status,
                invoice_id: data.invoice_id,
                delivery_order_date: data.delivery_order_date,
                delivery_order_received_date: data.delivery_order_received_date,
                producer_id: data.producer_id,
                storage_id: data.storage_id,
                notes: data.notes,
                printed_notes: data.printed_notes,
                address: data.address,
                attachment: null,
                production_delivery_order_detail: itemGridRequestOrder
            };

            const variables = {
                id : this.Id,
                data : deliveryData
            }
            
            this.$swal(sweet_alert.update_status).then((result) => {
                if (result.isConfirmed == true) {
                        productionDeliveryOrderServices.editQuery(variables).then(res => {
                        window.$('#ProductionDeliveryOrderStatusModal').modal('hide');
                        this.$swal("Info", response.successUpdate, "success");
                        this.$props.reload();
                    }).catch(error => {
                        var errorMessage = this.$globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            })
        },
    }
}
</script>

<style scoped>
.modal-xl-custom {
    max-width: 1500px;
}
</style>