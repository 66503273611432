<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>{{FormType}} Production Delivery Order : {{DeliveryNumber}}</h2>
            </CCardHeader>
            <CCardBody>
                <div class="px-3">
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Nomor Delivery</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="DeliveryNumber" v-model="DeliveryNumber" class="font-weight-bold"/>
                            <label id="errorDeliveryNumber" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Nomor Invoice</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="InvoiceNumber" v-model="InvoiceNumber" class="font-weight-bold" readonly/>
                            <label id="errorInvoiceNumber" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Status</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                            <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Tgl. Delivery</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <date-picker
                                v-model="DeliveryDate"
                                format="DD MMM YYYY"
                                type="date"
                                placeholder="Select date"
                                value-type="YYYY-MM-DD"
                                style="width:100%"
                            ></date-picker>
                            <label id="errorDeliveryDate" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Tgl. Penerimaan Delivery</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <date-picker
                                v-model="DeliveryReceivedDate"
                                format="DD MMM YYYY"
                                type="date"
                                placeholder="Select date"
                                value-type="YYYY-MM-DD"
                                style="width:100%"
                            ></date-picker>
                            <label id="errorDeliveryReceivedDate" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Producer</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="ProducerName" v-model="ProducerName" class="font-weight-bold" readonly/>
                            <label id="errorProducerName" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>  
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Gudang</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="StorageName" v-model="StorageName" class="font-weight-bold" readonly/>
                            <label id="errorStorageName" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Attachment</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                             <div class="row">
                                <div class="col-8 p-0 pl-3">
                                    <div class="form-control">
                                        <div v-if="this.FileStatus == 'Uploaded'">
                                            <a href="#" @click="fileClick()" class="label-file" id="uploadedFile"><label>{{File}}</label></a>
                                        </div>
                                        <div v-if="this.FileStatus == 'New'">
                                            <label class="label-file">{{File}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-2 p-0 px-2">
                                    <label for="File" class="form-control" style="text-align: center;"><i class="fa fa-upload"></i></label>
                                    <input type="file" id="File" @input="onChangeFile()" style="display:none"/>
                                </div>
                                <div class="col-2 p-0 pr-3">
                                    <CButton type="button" @click="deleteAttachmentClick()" id="deleteAttachmentButton" color="danger" style="width:100%"> <i class="fa fa-trash"></i> </CButton>
                                </div>
                            </div>
                            <label id="errorFile" class="form-error" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Alamat</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <textarea class="form-control" rows="2" v-model="Address"></textarea>
                            <label id="errorAddress" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Note</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <textarea class="form-control" rows="2" v-model="Note"></textarea>
                            <label id="errorNote" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Note Print</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <textarea class="form-control" rows="2" v-model="NotePrint"></textarea>
                            <label id="errorNotePrint" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <br>
                    <CRow class="p-3">
                        <div class="p-3 border rounded" style="width: 100%;">
                            <label class="form-label font-weight-bold" style="margin-bottom: 0px"><h4>Item</h4></label>
                            <hr>
                            <datasource ref="itemDataSource"    :data="this.ItemGridData" 
                                                                :page-size="10" 
                                                                :schema-model-fields="this.ItemSchemaModel"/>

                            <kendo-grid ref="gridItem"
                                        :data-source-ref="'itemDataSource'"
                                        :editable="EditableValue"
                                        :filterable="true"
                                        :sortable="true"
                                        :pageable="true"
                                        :cellClose="itemCellClose"
                                        :resizable="true"
                                        >

                                <kendo-grid-column  :field="'product_code'"
                                                    :title="'Kode'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'item_name'"
                                                    :title="'Nama'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'uom'"
                                                    :title="'Satuan'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                               
                                <kendo-grid-column  :field="'invoice_quantity'"
                                                    :title="'Invoice Quantity'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style:'text-align:right'}"></kendo-grid-column>

                                <kendo-grid-column  :field="'delivered_quantity'"
                                                    :title="'Delivered Quantity'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style:'text-align:right'}"></kendo-grid-column>

                                <kendo-grid-column  :field="'remaining_quantity'"
                                                    :title="'Remaining Quantity'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style:'text-align:right'}"></kendo-grid-column>

                                <kendo-grid-column  :field="'quantity'"
                                                    :title="'Quantity'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style:'text-align:right'}"></kendo-grid-column>
                            </kendo-grid>
                        </div>
                    </CRow>
                    <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                    <label style="float: right;">&nbsp;&nbsp;</label>
                    <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close" @click="$router.push('/production-delivery-order')"> Close </CButton>
                </div>
            </CCardBody>
        </CCard>
        <file-viewer ref="fileViewer"/>
    </div>
</template>

<script>
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { Grid, GridColumn} from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';
import productionDeliveryOrderServices from '../Script/ProductionDeliveryOrderServices';
import FileViewer from '../../../../shared/FileViewer.vue';
import moment from 'moment';
import { response } from '../../../../infrastructure/constant/response';
import { baseUrl } from '../../../../infrastructure/constant/connection';

export default {
    name: 'ProductionDeliveryOrderFormPage',
    components: {
        'date-picker' : datePicker,
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'datasource' : DataSource,
        'file-viewer': FileViewer,
    },
    async mounted () {
        if(this.FormType == 'Add'){
            this.EditableValue = true;
            var data = await productionDeliveryOrderServices.readProductionInvoiceDetailQuery(this.Id);
            
            this.DeliveryNumber= 'Auto';
            this.InvoiceId= data.invoice_id;
            this.InvoiceNumber= data.invoice_number;
            var dateNow = moment(new Date()).format("YYYY-MM-DD");
            this.DeliveryDate= dateNow;
            this.DeliveryReceivedDate= dateNow;
            this.StatusData = [ {value: 'New', label: 'New'} ];
            this.Status = 'New';
            this.ProducerId= data.producer_id;
            this.ProducerName= data.producer_name;
            this.StorageId = data.storage_id;
            this.StorageName = data.storage_name;

            //File
            this.File = '';
            this.FileStatus = 'New';

            this.Address = data.producer_address;
            this.Note = '';
            this.NotePrint = '';

            //grid
            this.ItemGridData = await productionDeliveryOrderServices.getProductionDeliveryOrderable(this.Id, this.Type);
    
            this.SaveType = 'Add';
        }else{
            this.EditableValue = false;
            var data = await productionDeliveryOrderServices.getDetailProductionDeliveryOrder(this.Id);

            this.DeliveryNumber= data.delivery_order_number;
            this.InvoiceId= data.invoice_id;
            this.InvoiceNumber= data.invoice_number;
            this.DeliveryDate= data.delivery_order_date;
            this.DeliveryReceivedDate= data.delivery_order_received_date;
            this.StatusData = await this.$globalfunc.globalDropdown('ddl_status_production_delivery_order');
            this.Status = data.status;
            this.ProducerId= data.producer_id;
            this.ProducerName= data.producer_name;
            this.StorageId = data.storage_id;
            this.StorageName = data.storage_name;

            //File
            this.File = data.attachment;
            this.FileStatus = 'Uploaded';

            this.Address = data.address;
            this.Note = data.notes;
            this.NotePrint = data.printed_notes;

            //Grid
            this.ItemGridData = data.detail;

            this.SaveType = 'Edit';

            document.getElementById('DeliveryNumber').readOnly = true;

            if(this.View == 'true'){
                $(".save-button").hide();
            }
        }
    },
    data(){
        return{
            FormType: this.$route.params.formtype,
            View: this.$route.params.view,
            Id: this.$route.params.id,
            Type: this.$route.params.type,
            Error : 0,

            //Detail
            DeliveryNumber: '',
            InvoiceId: '',
            InvoiceNumber: '',
            DeliveryDate: '',
            DeliveryReceivedDate: '',
            Status: '',
            StatusData: [],
            ProducerId: '',
            ProducerName: '',
            StorageId: '',
            StorageName: '',

            File: '',
            FileStatus: '',

            Address: '',
            Note: '',
            NotePrint: '',

            SaveType: '',
            EditableValue: false,

            // grid
            ItemGridData: [],
            ItemSchemaModel: {
                item_id: { type: "string", editable: false },
                product_code: { type: "string", editable: false },
                item_name: { type: "string", editable: false },
                uom: { type: "string", editable: false },
                invoice_quantity: { type: "number", editable: false },
                delivered_quantity: { type: "number", editable: false },
                remaining_quantity: { type: "number", editable: false },
                quantity: { type: "number", editable: true },
            },
        }
    },
    methods: {
        itemCellClose(e){
            var dataItem = e.sender.dataItem($(e.container).parent());
            if(dataItem.quantity == null){
                dataItem.quantity = 0;
            }
            if(dataItem.quantity > dataItem.remaining_quantity){
                dataItem.quantity = dataItem.remaining_quantity;
                this.$swal("Error", "Quantity tidak boleh lebih besar dari remaining quantity", "error");
            }
        },
        onChangeFile(){
            var file = document.getElementById('File').files[0];
            this.FileStatus = 'New';
            this.File = file.name;
        },
        fileClick(){
            this.$refs.fileViewer.viewClick(this.File);
        },
        inputValidation(){
            this.Error = 0;
            $(".form-error").css("display", "none");
            //Validation
            if(this.DeliveryNumber == '' || this.DeliveryNumber == null){
                this.errorShow('errorDeliveryNumber');
            }
            if(this.DeliveryDate == '' || this.DeliveryDate == null){
                this.errorShow('errorDeliveryDate');
            }
            if(this.DeliveryReceivedDate == '' || this.DeliveryReceivedDate == null){
                this.errorShow('errorDeliveryReceivedDate');
            }
            if(this.Address == ''){
                this.errorShow('errorAddress');
            }
            if(this.Note == ''){
                this.errorShow('errorNote');
            }
            if(this.ItemGridData.length < 1){
                this.$swal("Error", "Item tidak boleh kosong ", "error");
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
        async saveClick(){
            this.inputValidation();

            var file = document.getElementById('File').files[0];
            if(file == undefined)(
                file = null
            )

            //Proses
            if(this.Error == 0){
                //set grid
                var data = this.$refs.gridItem.kendoWidget().dataSource._data;
                var itemGridRequestOrder = [];

                if(data.length != 0 ){
                    for (let i = 0; i < data.length; i++) {
                        var str = { item_id: data[i].item_id,
                                    uom: data[i].uom,
                                    quantity: data[i].quantity
                                } 
                        itemGridRequestOrder.push(str);
                    }
                };

                var deliveryData = {
                    delivery_order_number: this.DeliveryNumber,
                    delivery_order_type: this.Type,
                    status: this.Status,
                    invoice_id: this.InvoiceId,
                    delivery_order_date: this.DeliveryDate,
                    delivery_order_received_date: this.DeliveryReceivedDate,
                    producer_id: this.ProducerId,
                    storage_id: this.StorageId,
                    notes: this.Note,
                    printed_notes: this.NotePrint,
                    address: this.Address,
                    attachment: file,
                    production_delivery_order_detail: itemGridRequestOrder
                };

                if (this.SaveType == 'Add'){
                    this.$loading(true);

                    const variables = {
                        data : deliveryData
                    }
                    
                    productionDeliveryOrderServices.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success").then(
                            this.$router.push({ path: '/production-delivery-order' })
                        );
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = this.$globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }else if (this.SaveType == 'Edit'){
                    this.$loading(true);

                    const variables = {
                        id : this.Id,
                        data : deliveryData
                    }

                    productionDeliveryOrderServices.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success")
                            .then(res => {
                                if(res.isConfirmed == true || res.isDismissed == true){
                                    window.location = baseUrl + '/production-delivery-order'
                                }
                            }); 
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        deleteAttachmentClick(){
            $('#File').val('');
            this.FileStatus = '';
            this.File = '';
        }
    }
}
</script>

<style scoped>
.col-sm-label, .col-sm-content, .col-sm-half {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
@media (min-width: 768px) {
    .col-sm-label, .col-sm-content, .col-sm-half {
        float: left;
    }
    .col-sm-label {
        width: 12%;
    }
    .col-sm-content {
        width: 21%;
    }
    .col-sm-half{
        width: 33%;
    }
    .input-number{
        text-align: right;
    }
}
>>> {
    --vs-controls-size: 0.7;
    --vs-actions-padding: 4px 2px 0 3px;
    --vs-border-color: #d8dbe0;
    --vs-selected-color: #768192;
    --vs-dropdown-option-color: #768192;
    --vs-selected-max-height: 10px;
}
.label-file {
  width : 100%;
  overflow:hidden;
  display:inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>