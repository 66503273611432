<template>
    <div>
        <router-view></router-view>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Production Delivery Order</h2>
            </CCardHeader>
            <CCardBody>
                <span v-html="statusButton"></span>

                <div class="col-xs-6 text-light pb-2">
                    <button id="add" class="btn btn-success"                   
                    @click="addClick()">
                        <i class="fa fa-plus"></i> Add
                    </button>
                </div>

                <production-delivery-order-create-form ref="productionDeliveryOrderCreateForm"/>
                <production-delivery-order-grid ref="grid" :key="gridReload" :editClick="editClick" :deleteClick="deleteClick" :statusClick="statusClick"/>
                <production-delivery-order-status-form ref="productionDeliveryOrderStatusForm" :reload="reload"/>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import productionDeliveryOrderGrid from './Grid/ProductionDeliveryOrderGrid.vue';
import productionDeliveryOrderCreateForm from './Component/ProductionDeliveryOrderCreateForm.vue';
import productionDeliveryOrderServices from './Script/ProductionDeliveryOrderServices';
import productionDeliveryOrderStatusForm from './Component/ProductionDeliveryOrderStatusForm.vue';
import { response } from '../../../infrastructure/constant/response';
import { sweet_alert } from '../../../infrastructure/constant/variable';

export default {
    name: 'ProductionDeliveryOrder',
    components: {
        'production-delivery-order-create-form': productionDeliveryOrderCreateForm,
        'production-delivery-order-grid': productionDeliveryOrderGrid,
        'production-delivery-order-status-form': productionDeliveryOrderStatusForm
    },
    async mounted () {
        var permission = this.$globalfunc.permissionModule('Production Delivery Order');
        document.getElementById("add").disabled = permission.cperms ? false : true;

        this.statusFilter();
    },
    data(){
        return{
            gridReload: 0,
            statusButton: '',
        }
    },
    methods: {
        addClick(){
            this.$refs.productionDeliveryOrderCreateForm.addClick();
        },
        editClick(data, view){
            // this.$router.push({ name: 'Production Delivery Order Form', params: { formtype:'Edit', id:data.delivery_order_id, view:view, type:data.delivery_order_type } });
            const routeData = this.$router.resolve({ name: 'Production Delivery Order Form', params: { formtype:'Edit', id:data.delivery_order_id, view:view, type:data.delivery_order_type } });
            window.open(routeData.href, '_blank');
        },
        async deleteClick(id){
            var data = await productionDeliveryOrderServices.getDetailProductionDeliveryOrder(id);

            //set grid
            var itemGridRequestOrder = [];

            if(data.detail.length != 0 ){
                for (let i = 0; i < data.detail.length; i++) {
                    var str = { item_id: data.detail[i].item_id,
                                uom: data.detail[i].uom,
                                quantity: data.detail[i].quantity
                            } 
                    itemGridRequestOrder.push(str);
                }
            };

            var deliveryData = {
                delivery_order_number: data.delivery_order_number,
                delivery_order_type: data.delivery_order_type,
                status: "Delete",
                invoice_id: data.invoice_id,
                delivery_order_date: data.delivery_order_date,
                delivery_order_received_date: data.delivery_order_received_date,
                producer_id: data.producer_id,
                storage_id: data.storage_id,
                notes: data.notes,
                printed_notes: data.printed_notes,
                address: data.address,
                attachment: null,
                production_delivery_order_detail: itemGridRequestOrder
            };

            const variables = {
                id : id,
                data : deliveryData
            }

            this.$swal(sweet_alert.delete).then((result) => {
                if (result.isConfirmed == true) {
                    productionDeliveryOrderServices.editQuery(variables).then(res => {
                        this.reload();
                        this.$swal("Info", response.successDelete, "success");                 
                    }).catch(error => {
                        var errorMessage = this.$globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            });
        },
        statusClick(data) {
            this.$refs.productionDeliveryOrderStatusForm.statusClick(data);
        },
        reload(){
            this.gridReload++;
            this.statusFilter();
        },
        async statusFilter() {
            // var countStatus = await this.$globalfunc.countTotal(window.location.pathname);
            // this.statusButton = await this.$globalfunc.dynamicStatusButton('ddl_status_production_delivery_order', countStatus);
            this.statusButton = await this.$globalfunc.dynamicStatusButton();
            
            var vue = this;
            this.$nextTick(function () {
                $("#btnAll").on('click', function() {
                    vue.reload();
                });
                $("#btnNew").on('click', function() {
                    vue.$refs.grid.changeStatus('New');
                });
                $("#btnPosting").on('click', function() {
                    vue.$refs.grid.changeStatus('Posting');
                });
                $("#btnDelivered").on('click', function() {
                    vue.$refs.grid.changeStatus('Delivered');
                })
                $("#btnDelete").on('click', function() {
                    vue.$refs.grid.changeStatus('Delete');
                })
            })
        },
    }
}
</script>

<style scoped>
</style>