<template>
    <div>
        <div class="modal fade" id="ProductionDeliveryOrderCreateModal" tabindex="-1" aria-labelledby="ProductionDeliveryOrderCreateModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl-custom modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="ProductionDeliveryOrderCreateModalLabel" class="font-weight-bold">Add Production Delivery Order</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <CRow>
                            <div class="col-3">
                                <label class="form-label font-weight-bold">Tipe Delivery Order</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </div>
                            <div class="col-3">
                                <CSelect id="Type" class="font-weight-bold" :options="TypeData" :value.sync="Type" />
                                <label id="errorType" class="form-error" style="display: none; color: red;"></label>
                            </div> 
                        </CRow>
                        <br>
                        <production-delivery-order-create-grid ref="grid" :key="gridReload" :createClick="createClick"/>
                        <br>
                        <CButton type="button" @click="createClick()" id="saveButton" color="success" style="float: right;"> Create </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>  
            </div>
        </div>
    </div>
</template>

<script>    
import productionDeliveryOrderCreateGrid from '../Grid/ProductionDeliveryOrderCreateGrid.vue';
// import invoiceServices from '../Script/InvoiceServices';
import { production } from '../../../../infrastructure/constant/variable';

export default {
    name: 'ProductionDeliveryOrderCreateForm',
    components: {
        'production-delivery-order-create-grid': productionDeliveryOrderCreateGrid,
    },
    mounted:  function () {
    },
    data: function () {
        return {
           gridReload: 0,
           TypeData : [],
           Type : production.send,
        }
    },
    methods: {
        addClick(){
            this.reload();
            this.TypeData = [ {value: 1, label: 'Send'}, {value: 2, label: 'Receive'} ];
            this.Type = production.send;
            window.$('#ProductionDeliveryOrderCreateModal').modal('show');
        },
        createClick(id){
            window.$('#ProductionDeliveryOrderCreateModal').modal('hide');
            this.$router.push({ name: 'Production Delivery Order Form', params: { formtype:'Add', id:id, view:'new', type: this.Type } });
        },
        reload(){
            this.gridReload++;
        },
    }
}
</script>
<style scoped>
.modal-xl-custom {
    max-width: 1500px;
}
.col-sm-label, .col-sm-content, .col-sm-half {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
@media (min-width: 768px) {
    .col-sm-label, .col-sm-content, .col-sm-half {
        float: left;
    }
    .col-sm-label {
        width: 12%;
    }
    .col-sm-content {
        width: 21%;
    }
    .col-sm-half{
        width: 33%;
    }
    .input-number{
        text-align: right;
    }
}
</style>